import './MainNav.css'
import { useState } from 'react'

const MainNav = ({ output, input }) => {
  const [activeItem, setActiveItem] = useState(2)
  console.log('gefe', input)
  const updateActive = id => {
    console.log('Setting active id ', id)
    output(id)
    setActiveItem(id)
  }

  return (
    <div className='sidebar'>
      <ul className='sidebar-navlist'>
        {input &&
          input.list.length > 0 &&
          input.list.map(listItem => (
            <li
              onClick={() => updateActive(listItem.id)}
              className={`sidebar-nav-list-item ${
                activeItem === listItem.id ? 'sidebar-item-active' : ''
              }`}
            >
              <img src={listItem.icon} alt='add item' className='asset-icon' />
              {listItem.name}
            </li>
          ))}
      </ul>
    </div>
  )
}

export default MainNav
// Home // Profile Setup // Inventory Manager // Orders // Billing // Statistics // Settings
