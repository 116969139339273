import { useEffect, useState } from 'react'
import fetchInventoryItemsList from '../../../../utility/features/InventoryManager/InventoryManager'
import { fetchImagesUrls } from '../../../../Firebase/Storage/getImages'
import TileItemViewer from '../TileItemViewer/TileItemViewer'
import ListItemViewer from './ListItemViewer'
import './InventoryViewer.css'

const InventoryViewer = ({ editMode, listView, tileView }) => {
  const [productsList, setProductsList] = useState([])
  const [imagesList, setImagesList] = useState([])

  useEffect(() => {
    fetchInventoryItemsList('9467904967', setProductsList)
    console.log(listView)
    // fetchImagesUrls('9467904967/inv/items', setImagesList)
  }, [])

  // useEffect(() => {
  //   console.log('fetching Storage ', itemsList)
  //   setItemsList(i => ++i)
  // }, [imagesList])

  // useEffect(() => {
  //   console.log('fetching DB ', itemsList)
  //   setItemsList(i => i++)
  // }, [productsList])

  return (
    <>
      <div className='inventory-viewer-items'>
        {productsList.length > 0 ? (
          <>
            {tileView && <TileItemViewer itemsList={productsList} />}
            {listView && <ListItemViewer itemsList={productsList} />}
          </>
        ) : (
          <div>Empty Inventory</div>
        )}
      </div>
    </>
  )
}

export default InventoryViewer
