import { useState, useEffect } from 'react'

import UploadImageToFirebase from '../../../../Firebase/Storage/ImageUpload'
import writeObjectToFirebase from '../../../../Firebase/Database/writeObjectToFirebase'

import './AddItemForm.css'
import NavButton from '../../../Design/Button/NavButton/NavButton'

const AddItemForm = ({ closeFormHandler, submitFormHandler, itemsList }) => {
  const [selectedFile, setimageFile] = useState(null)
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [pubView, setPubView] = useState(false)
  const [description, setDescription] = useState('')
  const [isImage, setisImage] = useState(false)
  const [addOpStatus, setAddOpStatus] = useState(null)

  const submitAddItemForm = e => {
    e.preventDefault()
    // alert('Adding item : ' + name + price + description + pubView)
  }
  const closeForm = e => {
    console.log('calling')
    console.log(closeFormHandler())
  }
  useEffect(() => {
    setTimeout(() => setAddOpStatus(null), 2000)
  }, [])
  // const onImageSelect = event => {
  //   setimageFile(event.target.files[0])
  //   setisImage(true)
  // }

  // const addItemHandler = () => {
  //   const uid = Date.now()
  //   const item = {
  //     name: name,
  //     price: price,
  //     pubView: pubView,
  //     description: description,
  //     uid: uid,
  //     isImage: isImage
  //   }
  //   UploadImageToFirebase('9467904967/inv/items/' + uid, selectedFile)
  //   writeObjectToFirebase(item, '9467904967/inv/items/' + uid)
  //   submitFormHandler()
  //   console.log(item, 'AddItemHandler')
  // }
  return (
    <div className='overlay'>
      <form className='add-item-form'>
        <div className='form-head'>
          <div className='pre-head'>
            {addOpStatus !== null && (
              <div
                className={`sm-bx ${addOpStatus ? 'success' : 'failed'} popup`}
              >
                {addOpStatus ? 'Added Item Successfully' : 'Failed to Add Item'}
              </div>
            )}
            <NavButton cssClass={'close-btn'} onClick={() => closeForm()}>
              x
            </NavButton>
          </div>
          <div className='title-md pad-t'>Add Product</div>
        </div>
        <div className='form-body'>
          <div className='form-inputs'>
            <label>Name</label>
            <input
              type='text'
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder='Name'
              required
            />
            <label>Price </label>
            <input
              type='number'
              onChange={e => setPrice(e.target.value)}
              value={price}
            />

            <label>Description</label>
            <input
              type='textarea'
              onChange={e => setDescription(e.target.value)}
              value={description}
            />
            <div className='toggle-input'>
              <label>Web View</label>
              <input
                type='checkbox'
                onChange={e => console.log(e.target.checked)}
                value={pubView}
              />
            </div>
          </div>
          <label>
            Add Image
            <input type='file' />
          </label>
        </div>
        <button
          type='submit'
          className='submit btn-md'
          onClick={e => submitAddItemForm(e)}
        >
          Add Item
        </button>
      </form>
    </div>
  )
}

export default AddItemForm
