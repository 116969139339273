import { ref, get } from 'firebase/database'
import { db } from '../../firebase'

const readDB = (where, then) => {
  read(where).then(data => then(data))
}

async function read (where) {
  console.log('Reading database @' + where)
  const data = await get(ref(db, where))

  if (data.exists()) {
    console.log('Database read complete @ ' + where + ' value: ', data.val())
    return Object.values(data.val())
  } else {
    console.log('No data available: ', where)
    return []
  }
}

export default readDB
