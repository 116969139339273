import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyCiuEvTTnUUtobY4IvW-7nMm6MVTa_KteA',
  authDomain: 'mehra01-e8c9f.firebaseapp.com',
  databaseURL:
    'https://mehra01-e8c9f-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'mehra01-e8c9f',
  storageBucket: 'mehra01-e8c9f.appspot.com',
  messagingSenderId: '791374477377',
  appId: '1:791374477377:web:cde53879f10830953faec0'
}

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const db = getDatabase(app)

export default app
