import './OptionsMenu.css'

const OptionsMenu = ({ list, showOption }) => {
  const onClickHandler = action => {
    showOption(false)
    action && action()
  }
  return (
    <div className='dropdown-content'>
      {list &&
        list.map((i, j) => (
          <a key={j} onClick={() => onClickHandler(i.action)}>
            {i.li}
          </a>
        ))}
    </div>
  )
}
export default OptionsMenu
