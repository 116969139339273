import { useState } from 'react'

import sampleImage from '../../../../data/static/sampleImage.png'
import getImage from '../../../../Firebase/Storage/getImage'
import RupeeIcon from './money.png'
import './Item.css'

const Item = ({ item, editMode, iurl }) => {
  console.log(iurl)
  return (
    <div className='iv-item'>
      <div className='ivi-image-holder'>
        <img
          src={iurl ? iurl : sampleImage}
          alt='Logo'
          className='iviih-image'
        />
        {editMode && (
          <div className='iviih-controls-edit'>
            <div className='ivi-hide-icon'></div>
            <div className='ivi-hide-icon'></div>
          </div>
        )}
      </div>
      <div className='ivi-details-holder'>
        <div className='ividh-name'>{item.name.toUpperCase()}</div>
        <div className='ividh-price'>
          <img src={RupeeIcon} alt='add item' className='asset-icon' />
          {item.price}
        </div>
        {item.likes !== null && <div className='ividh-name'></div>}
      </div>
    </div>
  )
}

export default Item
