import InventoryIcon from '../../assets/Sidebar/inventory.png'
import BillingIcon from '../../assets/Sidebar/bill.png'
import OrdersIcon from '../../assets/Sidebar/booking.png'
import MessagesIcon from '../../assets/Sidebar/comments.png'
import ThirdPartyIcon from '../../assets/Sidebar/cloud-computing.png'
import StatisticsIcon from '../../assets/Sidebar/statistics.png'
import DashboardIcon from '../../assets/Sidebar/dashboard.png'
import PaymentsIcon from '../../assets/Sidebar/payments.png'
import SettingsIcon from '../../assets/Sidebar/settings.png'
// Create Seperation between list and icons @ TODO

const list = [
  { name: 'Dashboard', id: 1, icon: DashboardIcon },
  { name: 'Billing', id: 2, icon: BillingIcon },
  { name: 'Orders', id: 3, icon: OrdersIcon }
]

const sidebarData = {
  list: list
}

export default sidebarData
