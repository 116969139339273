import { useState, useEffect } from 'react'
import InventoryViewer from './InventoryViewer/InventoryViewer'
import InventoyControls from './InventoryControls/InventoryControls'
import fetchInventoryItemsList from '../../../utility/features/InventoryManager/InventoryManager'
import './InventoryManager.css'
import AddItemForm from './AddItemForm/AddItemForm'
import { IoMdArrowBack } from 'react-icons/io'
const ListTypes = [
  'products',
  'images',
  'price',
  'ratings',
  'publicView',
  'description',
  'todaySales'
]

const items = {
  2223232: {
    itemName: 'pizza',
    itemPrice: 30
  }
}

const InventoryManager = props => {
  const user = '9467904967'
  const [productsList, setProductsList] = useState(null)
  const [addItemMode, setAddItemMode] = useState(false)

  useEffect(() => {}, [])
  return (
    <div className='inventory-managers'>
      <div className='inventory-viewer-wrapper'>
        <InventoryViewer listView={props.listView} tileView={props.tileView} />
      </div>
      {addItemMode && (
        <AddItemForm closeFormHandler={() => setAddItemMode(false)} />
      )}
    </div>
  )
}

export { ListTypes as InventoryLists }
export default InventoryManager
