import { useState } from 'react'
import './Screen.css'
import { IoMdArrowBack } from 'react-icons/io'
import { SlOptionsVertical } from 'react-icons/sl'
import OptionsMenu from './OptionsMenu'
import MainNav from './../Layout/MainNav/MainNav'
const Screen = ({
  children,
  heading,
  optionsList,
  optionsMenu,
  MainNavHandler
}) => {
  const [showOption, setShowOption] = useState(false)
  const [sideNav, setSideNav] = useState(false)
  return (
    <>
      <div className='screen-head'>
        <div onClick={() => MainNavHandler()}>
          <IoMdArrowBack />
        </div>
        <h3>{heading}</h3>
        <div className='screen-options dropdown'>
          {optionsMenu && (
            <>
              <SlOptionsVertical
                onClick={() => setShowOption(s => !s)}
                className='dropbtn'
              />

              {showOption && (
                <OptionsMenu list={optionsList} showOption={setShowOption} />
              )}
            </>
          )}
        </div>
      </div>
      <>{children}</>
    </>
  )
}

export default Screen
