const ListItem = ({ item, editMode }) => {
  return (
    <li>
      <div className='list-item-name'>{item.name}</div>
      <div className='list-item-price'>{item.price}</div>
    </li>
  )
}

const ListItemViewer = ({ itemsList, editMode, imagesList }) => {
  return (
    <ul className='list-item-viewer'>
      {itemsList.map((item, i) => {
        return <ListItem item={item} editMode={editMode} key={item.uid} />
      })}
    </ul>
  )
}

export default ListItemViewer
