import './NavButton.css'

const NavButton = props => {
  return (
    <div
      className={`btn-nav ${props.cssClass}`}
      onClick={e => props.onClick(e)}
    >
      {props.children}
    </div>
  )
}

export default NavButton
