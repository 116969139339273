import './App.css'
import { useState } from 'react'
import InventoryManager from './components/Features/InventoryManager/InventoryManager'
import Screen from './components/Design/Screen'
import MainNav from './components/Layout/MainNav/MainNav'
import sidebarData from './data/Layout/sidebar'
function App () {
  const [activeNavItem, setactiveNavItem] = useState(2)
  const [sideNav, setSideNav] = useState(false)

  const [addItem, setAddItem] = useState(false)
  const [editItem, setEditItem] = useState(false)
  const [selectItem, setSelectItem] = useState(false)
  const [tileView, setTileView] = useState(true)
  const [listView, setListView] = useState(false)

  const [sidebarList, SetSideBarList] = useState(sidebarData)

  const listViewHandler = () => {
    setTileView(false)
    setListView(true)
  }
  const MainNavHandler = flag => {
    console.log('Main Nav Clicked')
    setSideNav(s => !s)
  }
  const tileViewHandler = () => {
    console.log('ji')
    setListView(false)
    setTileView(true)
  }
  return (
    <div id='shop'>
      <main className='main'>
        <Screen
          heading={'Food Menu'}
          optionsMenu={true}
          optionsList={[
            { li: 'Add Item', action: setAddItem },
            { li: 'Select', action: setSelectItem },
            { li: 'Edit', action: setEditItem },
            {
              li: tileView ? 'List View' : 'Tile View',
              action: tileView ? listViewHandler : tileViewHandler
            }
          ]}
          MainNavHandler={MainNavHandler}
        >
          <InventoryManager
            addItem={addItem}
            listView={listView}
            tileView={tileView}
          />
          {sideNav && <MainNav input={sidebarList} />}
        </Screen>
      </main>
    </div>
  )
}

export default App
// f3d184
